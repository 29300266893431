<template>
	<ion-page>
		<ion-content :fullscreen="true">
			<ion-tabs>
				<ion-router-outlet></ion-router-outlet>
				<ion-tab-bar slot="top"> <!-- "bottom" -->
					<ion-tab-button tab="palletPrint" href="/palletPrint">
						<ion-icon :icon="newspaperOutline" />
						<ion-label>{{ palletPrintText }}</ion-label>
					</ion-tab-button>
					<ion-tab-button tab="lottoPrint" href="/lottoPrint">
						<ion-icon :icon="barcodeOutline" />
						<ion-label>{{ lottoPrintText }}</ion-label>
					</ion-tab-button>
					<ion-tab-button tab="itemsView" href="/itemsView">
						<ion-icon :icon="serverOutline" />
						<ion-label>{{ itemsViewText }}</ion-label>
					</ion-tab-button>
				</ion-tab-bar>
			</ion-tabs>
		</ion-content>
 </ion-page>
</template>

<style scoped>
</style>

<script>
// import { feName } from "@/plugins/variables.js";
import { IonPage, IonContent, IonTabs, IonRouterOutlet, IonTabBar, IonTabButton, IonLabel, IonIcon } from '@ionic/vue';
import { newspaperOutline, barcodeOutline, serverOutline } from "ionicons/icons";
import { getUserInfo, confirmYesNo } from "../plugins/common.js";
import { defineComponent } from 'vue';

	export default defineComponent({
		name: 'Home',
		props: {
			// pageTitle: { type: String, default: "Benvenuto in " + feName },
			palletPrintText: { type: String, default: "Stampa etichetta EAN" },
			lottoPrintText: { type: String, default: "Stampa etichetta Lotto" },
			itemsViewText: { type: String, default: "Visualizza Articoli" },
			messageTitle: { type: String, default: "Conferma" },
			warningMessage: { type: String, default: "Attenzione " },
			warningBranchConfirmMessage: { type: String, default: "Non ti è stato assegnato uno stabilimento. Alcune etichette protrebbero non venir stampate. Procedere lo stesso?" },
		},
		components: {
			IonPage,
			IonContent,
			IonTabs,
			IonRouterOutlet,
			IonTabBar,
			IonTabButton,
			IonLabel,
			IonIcon,
		},
		data: () => ({
			userInfo: null,
			newspaperOutline,
			barcodeOutline,
			serverOutline,
			getUserInfo,
			confirmYesNo,
		}),
		ionViewWillEnter() {
			this.setDefault();
		},
		methods: {
			//Actions
			setDefault() {
				this.userInfo = this.getUserInfo();
				if (!this.userInfo.branch_id) {
					this.confirmYesNo(this.messageTitle, this.warningMessage + this.userInfo.email + "<br><br>" + this.warningBranchConfirmMessage)
					.then((data) => {
						if (data == false) this.$router.push("/login");
					})
				}
			}
		},
	});
</script>
